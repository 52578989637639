.body {
  background-color: #291e50;
}

.container {
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(12, 8.3%);
  grid-template-rows: repeat(12, 1fr);
}

.earth-container {
  grid-column-start: 2;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 5;
  padding-right: 4em;
}
.title-container {
  grid-column-start: 4;
  grid-column-end: 10;
  grid-row-start: 6;
  grid-row-end: 7; 
}

.subtitle-container {
  grid-column-start: 4;
  grid-column-end: 10;
  grid-row-start: 7;
  grid-row-end: 8; 
}

.explanation-container {
  grid-column-start: 4;
  grid-column-end: 10;
  grid-row-start: 8;
  grid-row-end: 9; 
}

.poweredby-container {
  grid-column-start: 4;
  grid-column-end: 10;
  grid-row-start: 12;
  grid-row-end: 12; 
}



/* If the screen size is 601px wide or more, set the font-size of <div> to 80px */
@media screen and (min-width: 601px) {
  
  .title-container {
    font-family: "Edmondsans-medium";
    font-size: 3em;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    margin-bottom: 56px;
  }
  .subtitle-container {
    font-family: "Edmondsans-medium";
    font-size: 10em;
    color: #3c96dc;
    text-align: center;
    line-height: 1em;
    letter-spacing: 12px;
    margin-bottom: 40px;
  }
  .explanation-container {
    font-family: "Edmondsans";
    font-size: 2em;
    color: #ffffff;
    text-align: center;
    line-height: 1.5em;
  }
  .poweredby-container {
    font-family: "Edmondsans";
    font-size: 1em;
    color: #ffffff;
    text-align: center;
    line-height: 1.5em;
  }
}

/* If the screen size is 600px wide or less */
@media screen and (max-width: 600px) {
  .title-container {
    font-family: "Edmondsans";
    font-size: 2.8em;
    line-height: 1em;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
  }
  .subtitle-container {
    font-family: "Edmondsans-medium";
    font-size: 4em;
    color: #3c96dc;
    line-height: 2em;
    text-align: center;
  }
  .explanation-container {
    font-family: "Edmondsans";
    font-size: 1em;
    color: #ffffff;
    text-align: center;
    line-height: 1.6em;
  }
  .poweredby-container {
    font-family: "Edmondsans";
    font-size: 1em;
    color: #ffffff;
    text-align: center;
    line-height: 1.5em;
  }
  .stars-container-2 {
    display: none;
  }

  .sdgs {
    display: none;
  }
  .spaceship {
    display: none;
  }
  .moon {
    display: none;
  }
  .monster {
    display: none;
  }
}

.bhag-container {
  grid-column-start: 4;
  grid-column-end: 10;
  grid-row-start: 7;
  grid-row-end: 8;
}

.spaceship-container {
  grid-column-start: 7;
  grid-column-end: 8;
  grid-row-start: 2;
  grid-row-end: 4;
}

.sdgs-container {
  grid-column-start: 10;
  grid-column-end: 12;
  grid-row-start: 2;
  grid-row-end: 6;
}

.sdgs {
  width:16em;
}

.moon-container {
  grid-column-start: 10;
  grid-column-end: 12;
  grid-row-start: 8;
  grid-row-end: 10;
}

.monster-container {
  grid-column-start: 10;
  grid-column-end: 10;
  grid-row-start: 8;
  grid-row-end: 8;
}

.monster {
  margin-top: -2em;
  margin-left: 2em;
}

.ufo-container {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 9;
  grid-row-end: 11;
}

.ufo {
  width: 160px;
}

.stars-container {
  grid-column-start: 8;
  grid-column-end: 10;
  grid-row-start: 10;
  grid-row-end: 11;
}

.stars-container-2 {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 10;
  grid-row-end: 10;
}

.stars-container-3 {
  grid-column-start: 6;
  grid-column-end: 7;
  grid-row-start: 10;
  grid-row-end: 10;
  margin-top: 1em;
}

.stars-container-4 {
  grid-column-start: 9;
  grid-column-end: 10;
  grid-row-start: 2;
  grid-row-end: 4;
}

.stars-container-5 {
  grid-column-start: 12;
  grid-column-end: 13;
  grid-row-start: 3;
  grid-row-end: 4;
}

.stars-container-6 {
  grid-column-start: 6;
  grid-column-end: 7;
  grid-row-start: 2;
  grid-row-end: 4;
  margin-left: 3rem;
}

.powered {
  width: 12px;
  margin-right: 8px;
}